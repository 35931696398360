import moment from "moment";
import { BCPLocale } from "../helpers/rioti18n";
import { dateConfig } from "../config/dateConfig";
import DateFormat from "../data/enum/DateFormat";

export const isValidDate = (date: string, locale: BCPLocale) => {
  if (moment(date, dateConfig[locale]).year() < 1900) return false;

  return (
    date.length === 10 &&
    moment(date, dateConfig[locale]).isValid() &&
    moment().diff(moment(date, dateConfig[locale])) > 0
  );
};

export const getDateFromString = (date: string, locale: BCPLocale) => moment(date, dateConfig[locale]).toDate();

export const localizeDateString = (date: string, locale: BCPLocale) =>
  moment(date, DateFormat.default).format(dateConfig[locale]);

export const parseToUSDateFormat: (currentLocale: BCPLocale, date: string) => string = (currentLocale, date) => {
  if (dateConfig[currentLocale] === DateFormat["MM-DD-YYYY"] || dateConfig[currentLocale] === DateFormat.default)
    return date;

  const tokens = date.split("/");
  return dateConfig[currentLocale] === DateFormat["YYYY-MM-DD"]
    ? `${tokens[1]}/${tokens[2]}/${tokens[0]}`
    : `${tokens[1]}/${tokens[0]}/${tokens[2]}`; // DateFormat["DD-MM-YYYY"]
};
