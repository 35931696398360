import { BCPLocale } from "../helpers/rioti18n";
import DateFormat, { DateFormatType } from "../data/enum/DateFormat";

export const dateConfig: Record<BCPLocale, DateFormatType> = {
  [BCPLocale["ar-ae"]]: DateFormat["MM-DD-YYYY"],
  [BCPLocale["cs-cz"]]: DateFormat.default,
  [BCPLocale["de-de"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["el-gr"]]: DateFormat.default,
  [BCPLocale["en-au"]]: DateFormat["MM-DD-YYYY"],
  [BCPLocale["en-gb"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["en-ph"]]: DateFormat["MM-DD-YYYY"],
  [BCPLocale["en-pl"]]: DateFormat.default,
  [BCPLocale["en-sg"]]: DateFormat["MM-DD-YYYY"],
  [BCPLocale["en-us"]]: DateFormat["MM-DD-YYYY"],
  [BCPLocale["es-ar"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["es-es"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["es-mx"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["fr-fr"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["hu-hu"]]: DateFormat.default,
  [BCPLocale["id-id"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["ms-my"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["it-it"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["ja-jp"]]: DateFormat["YYYY-MM-DD"],
  [BCPLocale["ko-kr"]]: DateFormat["YYYY-MM-DD"],
  [BCPLocale["pl-pl"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["pt-br"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["ro-ro"]]: DateFormat.default,
  [BCPLocale["ru-ru"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["th-th"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["tr-tr"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["vi-vn"]]: DateFormat["DD-MM-YYYY"],
  [BCPLocale["zh-cn"]]: DateFormat["YYYY-MM-DD"],
  [BCPLocale["zh-tw"]]: DateFormat["YYYY-MM-DD"],
};
