const DateFormat = {
  default: "MM-DD-YYYY",
  "DD-MM-YYYY": "DD-MM-YYYY",
  "MM-DD-YYYY": "MM-DD-YYYY",
  "YYYY-MM-DD": "YYYY-MM-DD",
} as const;

export default DateFormat;

export type DateFormatType = keyof typeof DateFormat;
