import React, { useState, useRef, useEffect, useCallback } from "react";
import Icon from "../Icon/Icon";
import { icons } from "../Icon/icons";
import styles from "./CustomCursor.module.scss";

interface MousePosition {
  x: number;
  y: number;
}

interface CustomCursorProps {
  wrapperIcon: keyof typeof icons;
  startPosition: {
    clientX: number;
    clientY: number;
  };
}

const CustomCursor: React.FC<CustomCursorProps> = ({ wrapperIcon, startPosition }) => {
  const cursorRef = useRef<HTMLDivElement>(null);

  const [currentPosition, setCurrentPosition] = useState<MousePosition>({ x: 0, y: 0 });
  const [lastScrollY, setLastScrollY] = useState<number>(0);

  const updatePosition = useCallback(({ x, y }: MousePosition) => {
    const cursor = cursorRef.current;
    setCurrentPosition({ x, y });

    if (cursor) {
      cursor.style.left = `${x}px`;
      cursor.style.top = `${y}px`;
    }
  }, []);

  const handleMouseMove = useCallback(
    ({ clientX, clientY }) => {
      const cursor = cursorRef.current;
      if (cursor?.offsetParent) {
        const { top, left } = cursor.offsetParent.getBoundingClientRect();
        updatePosition({
          x: clientX - left,
          y: clientY - top,
        });
      }
    },
    [updatePosition],
  );

  const handleScroll = useCallback(() => {
    const scrolledY = window.scrollY - lastScrollY;
    updatePosition({
      ...currentPosition,
      y: currentPosition?.y + scrolledY,
    });
    setLastScrollY(window.scrollY);
  }, [lastScrollY, currentPosition, updatePosition]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    setLastScrollY(window.scrollY);
    const parent = cursorRef.current?.offsetParent;

    parent?.addEventListener("mousemove", handleMouseMove);
    handleMouseMove({ ...startPosition });

    return () => {
      parent?.removeEventListener("mousemove", handleMouseMove);
    };
  }, [startPosition, handleMouseMove]);

  return (
    <div className={styles.customCursor} ref={cursorRef}>
      <Icon className={styles.icon} name={wrapperIcon} />
    </div>
  );
};
export default CustomCursor;
