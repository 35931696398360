import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { hasTouch, isMS } from "../utils/browser-detect";

export const useMouseTracker = () => {
  const internalTargetRef = useRef<Element | null>(null);

  const [isMouseIn, setIsMouseIn] = useState(false);
  const [mousePosition, setMousePosition] = useState<{
    clientX: number;
    clientY: number;
  }>({ clientX: 0, clientY: 0 });

  const customCursor = useMemo(() => !isMS() && !hasTouch(), []);

  const targetRef = useCallback((node) => {
    internalTargetRef.current = node;
  }, []);

  const handleMouseEnter = useCallback(({ clientX, clientY }) => {
    setMousePosition({ clientX, clientY });
    setIsMouseIn(true);
  }, []);

  const handleMouseLeave = useCallback(() => setIsMouseIn(false), []);

  useEffect(() => {
    const currentTargetRef = internalTargetRef.current;

    if (customCursor && currentTargetRef) {
      currentTargetRef.addEventListener("mouseenter", handleMouseEnter);
      currentTargetRef.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (customCursor && currentTargetRef) {
        currentTargetRef.removeEventListener("mouseenter", handleMouseEnter);
        currentTargetRef.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [customCursor, handleMouseEnter, handleMouseLeave]);

  return { targetRef, data: { mousePosition, isMouseIn } };
};
