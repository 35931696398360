import React from "react";
import { ArticleType, ArticleTypeName } from "../../../data/enum/ArticleType";
import { Link } from "gatsby-plugin-intl";

type CardLinkProps = {
  articleType: ArticleTypeName;
  linkUrl: string;
  className?: string;
  sliderClassName?: string;
};

export const CardLink: React.FC<CardLinkProps> = ({ articleType, sliderClassName, className, linkUrl, children }) => {
  return articleType === ArticleType.NORMAL ? (
    <Link className={`${className} ${sliderClassName ? sliderClassName : ""}`} to={`${linkUrl}`}>
      {children}
    </Link>
  ) : (
    <a
      className={`${className} ${sliderClassName ? sliderClassName : ""}`}
      href={linkUrl}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
